/**
 *
 * Accomodations Page
 * 
*/

import React, { Fragment } from "react"

import BasePage from "./BasePage"
import Module from "../module/Module";
import ModuleBackground from "../module/ModuleBackground";
import ModuleContent from "../module/ModuleContent";
import ModuleLayer from "../module/ModuleLayer";
import TextLayer from "../module/layers/TextLayer";
import ImageLayer from "../module/layers/ImageLayer";
import VideoLayer from "../module/layers/VideoLayer";
import RichLayoutRow from "../richlayout/RichLayoutRow";
import RichLayoutColumn from "../richlayout/RichLayoutColumn";
import RichLayoutBody from "../richlayout/RichLayoutBody";
import Footer from "../Footer";
import ScrollDown from "../scrollDown/ScrollDown";
import { 
    accoData, 
} from "../../data/PageAssets";

export default class AccomodationsPage extends BasePage
{
    constructor(props)
    {
        super(props);

        this._isLazy = true;                            // lazy loading 
        this._assetPath = "accommodations";             // asset folder for page
        this._assets = accoData();                      // asset data

        this.state = {
            hasLoaded: false,
            hasLazyLoaded: false,
            hasResized: false,
        }
    }

    componentDidMount()
    {
        super.componentDidMount();
        
        // start initial asset loading...
        this.startAssets({ 
            isLazy: false, 
            onAssetEnd: () => this.onAssetEnd(this) 
        });
    }

    onAssetEnd()
    {
        this.setState({ hasLoaded: true });
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        const { hasLoaded, hasLazyLoaded } = this.state;

        if (hasLoaded !== prevState.hasLoaded)
        {
            this.initPage();
            return;
        }

        if (hasLazyLoaded !== prevState.hasLazyLoaded)
        {
            this.afterPageLoad();
        }
    }

    render()
    {
        const { hasLoaded, hasLazyLoaded: lz } = this.state;
        const { hasResized: rz } = this.props;

        const getVid = this.pVideoNam("acco_loop");
        const mProps = {
            hasLazyLoaded: lz,
            hasResized: rz,
            device: this._devSize
        }
        
        const videoConfig = { 
            offset: -15, 
            position: "bottom bottom", 
            phone: { 
                offset: 20, 
                position: "bottom center" 
            } 
        }

        return (
            <Fragment>
                <article ref={div => this._pageRef = div} className={"page accomodations"}>
                    <div className={"page-content"}>
                        <h1>Accommodations | A new concept in luxury rehabilitation.</h1>
                        <h2>Master Bedroom<span>600 square feet</span></h2>
                        <p>Upper Level Master: King size bed and luxurious ensuite bath including double vanity, soaker tub and double shower, 
                        private seating area, Television, A/C, and ocean views. This suite includes a private outdoor area 
                        with bar, jacuzzi and sun loungers. Easy handicap access to second floor via Bruno Chair Elevator.</p>
                        <h2>Bedroom 1</h2>
                        <p>Lower Level Suite: Queen size bed, ensuite bathroom, A/C, and garden views.</p>
                        <h2>Bedroom 2</h2>
                        <p>Lower Level Suite: Queen size bed, ensuite bathroom, A/C, and garden views.</p>
                        <h2>Bedroom 3</h2>
                        <p>Lower Level Suite: Double size bed + Single Bed, bathroom with wheel chair accessible toilet and shower and A/C.</p>
                        <h2>Bathroom 5</h2>
                        <p>Half bathroom by the Pool Area.</p>
                        <h2>Bathroom 6</h2>
                        <p>Powder Room off the Living/Dining Area.</p>
                        <h3>Key Indoor Amenities</h3>
                        <ul>
                            <li>A/C in bedrooms</li>
                            <li>TVs equipped with Netflix and Youtube</li>
                        </ul>
                        <h3>Key Outdoor Amenities</h3>
                        <ul>
                            <li>Swimming pool with wheel chair lift and waterfall cascade</li>
                            <li>Poolside Jacuzzi</li>
                            <li>Gas BBQ grill (option for a private chef)</li>
                            <li>Palapa with loungers and powder room</li>
                        </ul>
                    </div>
                    {
                        hasLoaded && 
                        <div className="modules">
                    
                            <Module isHeader={true} isFullSize={true} {...mProps}>
                                <ModuleBackground 
                                    device={this._devSize}
                                    image={this.assetRef("accomodations_hero_shot")}
                                    video={{ desktop: getVid, delay: this._isPhone ? this._delay : 0 }}
                                />
                                <ModuleContent 
                                    textContentClass="text-align-center align-center v-bottom"
                                    isFullSize={true}
                                    heading="Accommodations"
                                    body={"A new concept in luxury rehabilitation."}
                                />
                                <div ref={this._scrollRef} className="module-scroll-down show-scroll"><ScrollDown /></div>
                            </Module>

                            <ModuleLayer backgroundColor="white" ignoreTopPadding={true} {...mProps} video={videoConfig}>

                                <TextLayer classes="bottom-space" titleHtml={"Bedrooms and Bathrooms"} x={1089} y={138} width={701} height={491}>
                                    <p className="sub-heading master-bed-sub">Master Bedroom<span className="sub-head-div">600 square feet</span></p>
                                    <p className="">Upper Level Master: King size bed and luxurious ensuite bath including double vanity, soaker tub and double shower, 
                                        private seating area, Television, A/C, and ocean views. This suite includes a private outdoor area 
                                        with bar, jacuzzi and sun loungers. Easy handicap access to second floor via Bruno Chair Elevator.</p>
                                </TextLayer>

                                <ImageLayer 
                                    alt="Casa Lupita Master Bedroom"
                                    asset={this.assetRef("master_01_1")}
                                    x={0} y={107} width={978} height={657}
                                />

                                <ImageLayer 
                                    alt="Casa Lupita Master Bathroom"
                                    asset={this.assetRef("master_bath_01_1")}
                                    x={1078} y={682} width={842} height={560}
                                />
                                
                                {/*<ImageLayer 
                                    classes="bottom-space"
                                    alt="Casa Lupita Master Bedroom"
                                    asset={this.assetRef("master_01_2")}
                                    x={297} y={859} width={681} height={383}
                                />*/}

                                {<VideoLayer 
                                    hasLazyLoaded={lz}
                                    poster={this.assetRef("master_01_2").src()}
                                    asset={this.withPrefix("static/videos/minis/hd/acco_681x383.mp4")}
                                    x={297} y={859} width={681} height={383}
                                />}

                            </ModuleLayer>
                            
                            <ModuleLayer backgroundColor="white" ignoreTopPadding={true} {...mProps}>

                                <TextLayer classes="bottom-space" x={373} y={107} width={550} height={230}>
                                    <p className="sub-heading">Bedroom 1</p>
                                    <p>Lower Level Suite: Queen size bed, ensuite bathroom, A/C, and garden views.</p>
                                </TextLayer>

                                <ImageLayer 
                                    alt="Casa Lupita Bedroom 01"
                                    asset={this.assetRef("bedroom_01_1")}
                                    x={0} y={420} width={978} height={651}
                                />

                                <ImageLayer 
                                    classes="bottom-space"
                                    alt="Casa Lupita Bedroom 01 Bathroom"
                                    asset={this.assetRef("bedroom_01_bath_1")}
                                    x={1078} y={107} width={635} height={716}
                                />
                                
                                <TextLayer classes="bottom-space" x={463} y={1126} width={520} height={210}>
                                    <p className="sub-heading">Bedroom 2</p>
                                    <p>Lower Level Suite: Queen size bed, ensuite bathroom, A/C, and garden views.</p>
                                </TextLayer>

                                <ImageLayer 
                                    alt="Casa Lupita Bedroom 02"
                                    asset={this.assetRef("bedroom_02_1")}
                                    x={1078} y={930} width={842} height={1122}
                                />

                                <ImageLayer 
                                    classes="bottom-space"
                                    alt="Casa Lupita Bedroom 02 Bathroom"
                                    asset={this.assetRef("bedroom_02_bath_1")}
                                    x={343} y={1412} width={635} height={716}
                                />

                            </ModuleLayer>
                            
                            <ModuleLayer backgroundColor="white" paddingBottom={0} ignoreTopPadding={true} {...mProps}>

                                <TextLayer classes="bottom-space" x={1078} y={62} width={506} height={226}>
                                    <p className="sub-heading">Bedroom 3</p>
                                    <p>Lower Level Suite: Double size bed + Single Bed, bathroom with wheel chair accessible toilet and shower and A/C.</p>
                                </TextLayer>

                                <ImageLayer 
                                    alt="Casa Lupita Bedroom 03"
                                    asset={this.assetRef("bedroom_03_1")}
                                    x={0} y={107} width={978} height={680}
                                />

                                <ImageLayer 
                                    alt="Casa Lupita Bathroom"
                                    asset={this.assetRef("bedroom_03_2")}
                                    x={1078} y={387} width={842} height={566}
                                />
                                
                                <ImageLayer 
                                    alt="Casa Lupita Bathroom"
                                    asset={this.assetRef("bedroom_03_bath_1")}
                                    x={122} y={887} width={856} height={610}
                                />
                                
                                <ImageLayer 
                                    alt="Casa Lupita Bathroom"
                                    asset={this.assetRef("bedroom_03_bath_2")}
                                    x={229} y={1603} width={749} height={557}
                                />
                                
                                <ImageLayer 
                                    alt="Casa Lupita Bathroom"
                                    asset={this.assetRef("bedroom_03_bath_3")}
                                    x={1078} y={1052} width={738} height={1108}
                                />

                            </ModuleLayer>
                            
                            <ModuleLayer backgroundColor="white" paddingBottom={0} ignoreTopPadding={true} {...mProps}>

                                <TextLayer x={373} y={107} width={438} height={140}>
                                    <p className="sub-heading">Bathroom 5</p>
                                    <p>Half bathroom by the Pool Area.</p>
                                </TextLayer>
                                
                                <TextLayer classes="bottom-space trim-top" x={373} y={251} width={438} height={170}>
                                    <p className="sub-heading">Bathroom 6</p>
                                    <p>Powder Room off the Living/Dining Area.</p>
                                </TextLayer>

                                <TextLayer classes="bottom-space" x={373} y={611} width={530} height={240} titleText={"Key Indoor Amenities"}>
                                    <ul>
                                        <li>A/C in bedrooms</li>
                                        <li>TVs equipped with Netflix and Youtube</li>
                                    </ul>
                                </TextLayer>

                                <ImageLayer 
                                    alt="Casa Lupita Indoor Amenities"
                                    asset={this.assetRef("indoor_ameneties_1")}
                                    x={978} y={107} width={942} height={735}
                                />

                            </ModuleLayer>
                            
                            <ModuleLayer backgroundColor="white" paddingBottom={0} ignoreTopPadding={false} {...mProps}>

                                <TextLayer classes="bottom-space" x={1078} y={112} width={760} height={330} titleText={"Key Outdoor Amenities"}>
                                    <ul>
                                        <li>Swimming pool with wheel chair lift and waterfall cascade</li>
                                        <li>Poolside Jacuzzi</li>
                                        <li>Gas BBQ grill (option for a private chef)</li>
                                        <li>Palapa with loungers and powder room</li>
                                    </ul>
                                </TextLayer>

                                <ImageLayer 
                                    alt="Casa Lupita Jacuzzi"
                                    asset={this.assetRef("outdoor_ameneties_1")}
                                    x={143} y={107} width={835} height={1254}
                                />

                                <ImageLayer 
                                    alt="Casa Lupita Pool"
                                    asset={this.assetRef("outdoor_ameneties_2")}
                                    x={1078} y={494} width={842} height={611}
                                />

                                <ImageLayer 
                                    alt="Casa Lupita Outside Bar"
                                    asset={this.assetRef("outdoor_ameneties_3")}
                                    x={316} y={1455} width={662} height={471}
                                />
                                
                                <ImageLayer 
                                    alt="Casa Lupita Pool at night"
                                    asset={this.assetRef("outdoor_ameneties_4")}
                                    x={1078} y={1199} width={759} height={506}
                                />

                            </ModuleLayer>

                            <ModuleLayer 
                                backgroundColor="white" 
                                {...mProps}
                                animate="push"
                            >
                                <ImageLayer 
                                    alt="Casa Lupita night poolside."
                                    asset={this.assetRef("night_pool_1")}
                                    x={0} y={0} width={1920} height={1301}
                                />

                            </ModuleLayer>
                            
                            <Module 
                                device={this._devSize}
                                hasRichLayout={true} 
                                classes="m-footer" 
                                backgroundColor="#0a0a0a" 
                                animate="footer"
                                hasLazyLoaded={lz}
                            >
                                <ModuleContent classes="full-width">
                                    <RichLayoutRow classes="">
                                        <RichLayoutColumn classes="flex-v-align-center">
                                            <RichLayoutBody>
                                                <Footer owner={this} pageRef={this._pageRef} hasLazyLoaded={lz} />
                                            </RichLayoutBody>
                                        </RichLayoutColumn> 
                                    </RichLayoutRow>
                                </ModuleContent>
                            </Module>

                        </div>
                    }

                </article>
            </Fragment>
        )
    }  
}